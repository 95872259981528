import {
    fetchingDataWithAxiosMiddleware,
    set_loading,
    set_response_notification,
    setInProgressMaterialLoading,
    setServerIsDown,
} from "./commonAction";
import apiService from "../../serviceUtils";
import {changeResponseDate, queryParamsForCommonFilter} from "../../config";

export const SET_FLOORS = "SET_FLOORS";

export const SET_IN_PROGRESS_FLOORS = "SET_IN_PROGRESS_FLOORS";

export const set_in_progress_floors = (payload) => {
    return {
        type: SET_IN_PROGRESS_FLOORS,
        payload,
    };
};
export const set_floors = (payload) => {
    return {
        type: SET_FLOORS,
        payload,
    };
};

export const get_filter_floors = (filterData) => async (dispatch) => {
    try {
        dispatch(set_loading({type: "floors", value: true}));
        const params = filterData
            ? filterData
            : JSON.parse(sessionStorage.getItem("filterParams")).floors;
        const response = await fetchingDataWithAxiosMiddleware(
            "GET",
            `${apiService.get_filter_floor_tiles()}?${queryParamsForCommonFilter(
                params
            )}`
        );
        if (response.status) {
            const data = await response.data;
            if (data) {
                dispatch(set_floors(changeResponseDate(data, "floors")));
            } else if (response.status === 204) {
                dispatch(set_floors(changeResponseDate([], "floors")));
            }
            dispatch(set_loading({type: "floors", value: false}));
        }
    } catch (error) {
        if (error.message === "Network Error") {
            dispatch(setServerIsDown(true));
        }
        dispatch(set_loading({type: "floors", value: false}));
        dispatch(
            set_response_notification({
                type: "error",
                text: "Error -> " + error.message,
            })
        );
    }
};
export const add_floor = (requestData) => async (dispatch) => {
    try {
        const formData = new FormData();
        formData.append("name", requestData.name.trim());
        formData.append("code", requestData.code);
        formData.append("url", requestData.url);
        // requestData.sewingPattern &&
        //     formData.append("sewingPattern", requestData.sewingPattern);
        // TODO this must be remove from database too
        requestData.sewingPattern ?
            formData.append("sewingPattern", requestData.sewingPattern) :
            formData.append("sewingPattern", "staggered");
        formData.append("type", requestData.type);
        requestData.vendorID && formData.append("vendorID", requestData.vendorID);
        dispatch(set_loading({type: "floors", value: true}));
        const response = await fetchingDataWithAxiosMiddleware(
            "POST",
            `${apiService.floors()}`,
            formData
        );
        if (response?.status) {
            for (const item of requestData.material) {
                const index = requestData.material.indexOf(item);
                const formDataForTile = new FormData();
                formDataForTile.append("code", requestData.code);
                formDataForTile.append(
                    "final",
                    requestData.material[index + 1] === null
                );
                formDataForTile.append("file", item?.file);
                formDataForTile.append("length", +item?.length);
                if (item) {
                    await fetchingDataWithAxiosMiddleware(
                        "POST",
                        `${apiService.add_floor_tile()}`,
                        formDataForTile
                    ).finally(() => {
                        if (requestData.material[index + 1] === null) {
                            dispatch(get_filter_floors());
                            dispatch(
                                set_response_notification({type: "success", text: "Success"})
                            );
                        }
                    });
                }
            }
            dispatch(set_loading({type: "floors", value: false}));
            // }
        }
    } catch (error) {
        dispatch(
            set_response_notification({
                type: "error",
                text: "Error -> " + error.message,
            })
        );
        dispatch(set_loading({type: "floors", value: false}));
    }
};
export const edit_floor = (data) => async (dispatch) => {
    try {
        const formData = new FormData();
        formData.append("id", data.key);
        data.name && formData.append("name", data.name.trim());
        data.code && formData.append("code", data.code);
        data.url && formData.append("url", data.url);
        data.material && formData.append("floorIcon", data.material);
        data.vendorID && formData.append("vendorID", data.vendorID);
        formData.append("editable", data.editable);
        dispatch(set_loading({type: "floors", value: true}));
        const response = await fetchingDataWithAxiosMiddleware(
            "PUT",
            `${apiService.floors()}`,
            formData
        );
        if (response?.status) {
            dispatch(get_filter_floors());
            dispatch(set_response_notification({type: "success", text: "Success"}));
        }
    } catch (error) {
        dispatch(
            set_response_notification({
                type: "error",
                text: "Error -> " + error.message,
            })
        );
        dispatch(set_loading({type: "floors", value: false}));
    }
};
export const delete_floor = (data) => async (dispatch) => {
    try {
        dispatch(set_loading({type: "floors", value: true}));
        const response = await fetchingDataWithAxiosMiddleware(
            "DELETE",
            `${apiService.floors()}/${data}`
        );
        if (response?.status) {
            dispatch(get_filter_floors());
            dispatch(set_response_notification({type: "success", text: "Success"}));
        }
    } catch (error) {
        dispatch(
            set_response_notification({
                type: "error",
                text: "Error -> " + error.message,
            })
        );
        dispatch(set_loading({type: "floors", value: false}));
    }
};
export const floors_in_preprocess = (id) => async (dispatch) => {
    try {
        const response = await fetchingDataWithAxiosMiddleware(
            "GET",
            `${apiService.floors_in_preprocess()}/${id ? id : ""}`
        );
        if (response.status) {
            const data = await response.data;
            if (data) {
                if (data.length) {
                    const preprocess = data.filter(
                        (item) =>
                            item.status === "in preprocess" ||
                            item.status === "not preprocessed"
                    ).length;
                    if (preprocess) {
                        dispatch(
                            set_in_progress_floors(changeResponseDate(data, "floors"))
                        );
                    } else {
                        dispatch(get_filter_floors());
                        dispatch(
                            setInProgressMaterialLoading({type: "floors", value: false})
                        );
                        dispatch(set_in_progress_floors(null));
                    }
                }
            }
        }
    } catch (error) {
        dispatch(
            set_response_notification({
                type: "error",
                text: "Error -> " + error.message,
            })
        );
        dispatch(setInProgressMaterialLoading({type: "floors", value: false}));
    }
};
export const preprocess_floors = (id) => async (dispatch) => {
    try {
        dispatch(setInProgressMaterialLoading({type: "floors", value: id}));
        await fetchingDataWithAxiosMiddleware(
            "GET",
            `${apiService.preprocess_floors()}/${id ? id : ""}`
        );
        if (id) {
            dispatch(setInProgressMaterialLoading({type: "floors", value: id}));
        } else {
            dispatch(setInProgressMaterialLoading({type: "floors", value: false}));
        }
    } catch (error) {
        dispatch(setInProgressMaterialLoading({type: "floors", value: false}));
        dispatch(
            set_response_notification({
                type: "error",
                text: "Error -> " + error.message,
            })
        );
    }
};
export const transform_floor = (data) => async (dispatch) => {
    try {
        dispatch(set_loading({type: "floors", value: true}));
        const formData = new FormData();
        formData.append("floorTileID", data.floorTileID);
        formData.append("zoom", data.zoom);
        dispatch(set_loading(true));
        const response = await fetchingDataWithAxiosMiddleware(
            "POST",
            `${apiService.transform_floor()}`,
            formData
        );
        if (response?.status) {
            dispatch(get_filter_floors());
            dispatch(set_response_notification({type: "success", text: "Success"}));
        }
    } catch (error) {
        dispatch(set_loading({type: "floors", value: false}));
        dispatch(
            set_response_notification({
                type: "error",
                text: "Error -> " + error.message,
            })
        );
        dispatch(set_loading(false));
    }
};
export const pause_floor = (data) => async (dispatch) => {
    try {
        dispatch(set_loading({type: "floors", value: true}));
        const response = await fetchingDataWithAxiosMiddleware(
            "POST",
            `${apiService.floors()}/pause/${data}`
        );
        if (response?.status) {
            dispatch(set_response_notification({type: "success", text: "Success"}));
            dispatch(get_filter_floors());
        }
    } catch (error) {
        dispatch(
            set_response_notification({
                type: "error",
                text: "Error -> " + error.message,
            })
        );
        dispatch(set_loading({type: "floors", value: false}));
    }
};
