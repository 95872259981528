import React, { useEffect, useRef } from "react";
import { Button, Spin, Switch, Table, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  set_is_modal,
  set_material_id_for_processing,
  set_min_width_header,
  set_tablesPageInfo,
} from "../../Redux/Actions/commonAction";
import { get_vendors } from "../../Redux/Actions/vendor";

import {
  get_filter_walls,
  pause_wall,
  preprocess_walls,
  walls_in_preprocess,
} from "../../Redux/Actions/wall";
import { calculateTotal, showColumns, showDetails } from "../../config";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";

const Walls = () => {
  const state = useSelector((state) => state.common);
  const {
    walls,
    vendors,
    loading,
    auth_info,
    inProgressMaterialLoading,
    in_progress_walls,
    is_open_processing_modal,
    material_id_for_processing,
    checked_vendor_paused,
    tablesPageInfo,
  } = state;
  const dispatch = useDispatch();

  const tableRef = useRef(null);

  const tablePageOnChange = (e) => {
    dispatch(set_tablesPageInfo({ ...tablesPageInfo, walls: e }));
  };
  const startProcessing = (id) => {
    dispatch(set_material_id_for_processing(id));
    dispatch(preprocess_walls(id));
    setTimeout(() => {
      dispatch(walls_in_preprocess(id));
    }, 2000);
  };
  let wallsColumns = [
    {
      title: "ID",
      dataIndex: "key",
      forVendor: false,
      sorter: (a, b) => b.key - a.key,
    },
    {
      title: (
        <span>
          Name <br /> Total ({calculateTotal(walls, "name")})
        </span>
      ),
      dataIndex: "name",
      forVendor: true,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Vendor Name",
      dataIndex: "vendorID",
      forVendor: false,
      render: (record) => {
        const vendor = vendors.find((vendor) => +vendor.key === record);
        return vendor ? vendor.name : "no vendor";
      },
    },
    {
      title: "Color",
      dataIndex: "color",
      forVendor: true,
      render: (record) => (
        <div
          className={"colorDiv"}
          onClick={() =>
            record &&
            dispatch(set_is_modal({ open: record, type: "view_photo" }))
          }
          style={{ background: record }}
          title={record}
        />
      ),
    },
    {
      title: "Material Status",
      dataIndex: "",
      forVendor: false,
      render: (record) => {
        return (
          <>
            <Switch
              checked={record.paused}
              checkedChildren="Paused"
              unCheckedChildren="Live"
              onChange={() => {
                dispatch(pause_wall(record.key));
              }}
            />
            <br />
            {record.impressionLimit}
          </>
        );
      },
    },
    {
      title: (
        <span>
          Impressions <br /> Total ({calculateTotal(walls, "impressions")})
        </span>
      ),
      dataIndex: "",
      forVendor: true,
      sorter: (a, b) => b.impressions?.length - a.impressions?.length,
      render: (record) => showDetails(record, "impressions", dispatch),
    },
    {
      title: (
        <span>
          Clicks <br /> Total ({calculateTotal(walls, "clicks")})
        </span>
      ),
      dataIndex: "",
      forVendor: true,
      sorter: (a, b) => b.clicks?.length - a.clicks?.length,
      render: (record) => showDetails(record, "clicks", dispatch),
    },
    {
      title: (
        <span>
          Cost <br /> Total (${calculateTotal(walls, "cost").toFixed(2)})
        </span>
      ),
      dataIndex: "cost",
      forVendor: true,
      sorter: (a, b) => b.cost - a.cost,
      render: (record) => `$${record.toFixed(2)}`,
    },
    {
      title: "Status",
      dataIndex: "",
      forVendor: true,
      sorter: (a, b) => a.preprocessed - b.preprocessed,
      render: (record) => (
        <>
          {record.preprocessed ? (
            <span>Live</span>
          ) : +record.key === +inProgressMaterialLoading.walls ? (
            <Spin />
          ) : (
            <Button
              disabled={inProgressMaterialLoading.walls || loading}
              onClick={() => startProcessing(record.key)}
            >
              Push Live
            </Button>
          )}
        </>
      ),
    },
    {
      title: (
        <span>
          Forms <br /> Total ({calculateTotal(walls, "formIds")})
        </span>
      ),
      dataIndex: "",
      forVendor: false,
      sorter: (a, b) => b.formIds?.length - a.formIds?.length,
      render: (record) => (
        <div className={"impressionsRow"}>
          <span>{record.formIds?.length}</span>
          {record.formIds?.length ? (
            <Button
              size={"small"}
              onClick={() =>
                dispatch(
                  set_is_modal({
                    open: {
                      formIds: record.formIds,
                      name: record.name,
                    },
                    type: "forms",
                  })
                )
              }
            >
              View
            </Button>
          ) : (
            ""
          )}
        </div>
      ),
    },

    {
      title: "Logo",
      dataIndex: "",
      forVendor: false,
      render: (record) =>
        record.key !== 0 ? (
          <img
            src={record.logo}
            style={{ cursor: "pointer" }}
            onClick={() =>
              record.logo &&
              dispatch(set_is_modal({ open: record.logo, type: "view_photo" }))
            }
            alt="wall logo"
            width={30}
          />
        ) : (
          ""
        ),
    },
    {
      title: "Actions",
      dataIndex: "",
      forVendor: true,
      sorter: (a, b) => b.editable - a.editable,
      render: (record) => (
        <div className="actions">
          {record.preprocessed ? (
            <Tooltip title="View Sphere">
              <EyeOutlined
                style={{ marginRight: "10px" }}
                onClick={() => {
                  window.open(record.sphereUrl, "_blank");
                }}
              />
            </Tooltip>
          ) : (
            ""
          )}
          {record.editable &&
          +record.key !== +inProgressMaterialLoading.walls ? (
            <>
              <Tooltip title="Edit">
                <EditOutlined
                  onClick={() => {
                    dispatch(set_is_modal({ open: record, type: "edit" }));
                    // dispatch(set_active_item_for_change(record));
                  }}
                />
              </Tooltip>
              {!auth_info.isVendor ? (
                <Tooltip title="Delete">
                  <DeleteOutlined
                    onClick={() => {
                      dispatch(set_is_modal({ open: record, type: "delete" }));
                      // dispatch(set_active_item_for_change(record));
                    }}
                  />
                </Tooltip>
              ) : (
                ""
              )}
            </>
          ) : (
            "Non Editable"
          )}
        </div>
      ),
    },
  ];
  useEffect(() => {
    if (in_progress_walls?.length || inProgressMaterialLoading.walls) {
      if (!is_open_processing_modal) {
        if (material_id_for_processing) {
          setTimeout(() => {
            dispatch(walls_in_preprocess(material_id_for_processing));
          }, 7000);
        }
      }
    }
  }, [
    in_progress_walls,
    material_id_for_processing,
    is_open_processing_modal,
    inProgressMaterialLoading.walls,
  ]);
  useEffect(() => {
    if (!walls.length) {
      dispatch(get_filter_walls());
    }
    if (!vendors.length && !auth_info.isVendor) {
      dispatch(get_vendors());
    }
  }, [dispatch, checked_vendor_paused]);
  useEffect(() => {
    dispatch(
      set_min_width_header(
        tableRef?.current?.children[0].children[0].children[0].children[0]
          .children[0].children[0]?.offsetWidth
      )
    );
  }, [tableRef, dispatch]);
  return (
    <div>
      <Table
        ref={tableRef}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        size={"small"}
        className={"globalTable"}
        loading={loading.walls}
        dataSource={walls}
        columns={showColumns(wallsColumns, vendors.length)}
        bordered
        onChange={tablePageOnChange}
        pagination={tablesPageInfo.walls}
      />
    </div>
  );
};

export default Walls;
