import {
  fetchingDataWithAxiosMiddleware,
  set_loading,
  set_response_notification,
  setInProgressMaterialLoading,
  setServerIsDown,
} from "./commonAction";
import apiService from "../../serviceUtils";
import { changeResponseDate, queryParamsForCommonFilter } from "../../config";

export const SET_WALLS = "SET_WALLS";
export const SET_IN_PROGRESS_WALLS = "SET_IN_PROGRESS_WALLS";

export const set_in_progress_walls = (payload) => {
  return {
    type: SET_IN_PROGRESS_WALLS,
    payload,
  };
};

export const set_walls = (payload) => {
  return {
    type: SET_WALLS,
    payload,
  };
};

export const get_filter_walls = (filterData) => async (dispatch) => {
  try {
    const params = filterData
      ? filterData
      : JSON.parse(sessionStorage.getItem("filterParams")).walls;
    dispatch(set_loading({ type: "walls", value: true }));
    const response = await fetchingDataWithAxiosMiddleware(
      "GET",
      `${apiService.get_filter_wall_colors()}?${queryParamsForCommonFilter(
        params
      )}`
    );
    if (response.status) {
      const data = await response.data;
      if (data) {
        dispatch(set_walls(changeResponseDate(data, "walls")));
      } else if (response.status === 204) {
        dispatch(set_walls(changeResponseDate([], "walls")));
      }
      dispatch(set_loading({ type: "walls", value: false }));
    }
  } catch (error) {
    if (error.message === "Network Error") {
      dispatch(setServerIsDown(true));
    }
    dispatch(set_loading({ type: "walls", value: false }));
    dispatch(
      set_response_notification({
        type: "error",
        text: "Error -> " + error.message,
      })
    );
  }
};

export const add_wall = (data) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append("name", data.name.trim());
    formData.append("url", data.url);
    formData.append("hexCode", data.color);
    formData.append("skuNumber", data.skuNumber);
    data.vendorID && formData.append("vendorID", data.vendorID);
    dispatch(set_loading({ type: "walls", value: true }));
    const response = await fetchingDataWithAxiosMiddleware(
      "POST",
      `${apiService.walls()}`,
      formData
    );
    if (response?.status) {
      dispatch(get_filter_walls());
      dispatch(set_response_notification({ type: "success", text: "Success" }));
    }
  } catch (error) {
    dispatch(
      set_response_notification({
        type: "error",
        text: "Error -> " + error.message,
      })
    );
    dispatch(set_loading({ type: "walls", value: false }));
  }
};
export const edit_wall = (data) => async (dispatch) => {
  dispatch(set_loading({ type: "walls", value: true }));
  try {
    const formData = new FormData();
    formData.append("id", data.key);
    data.name && formData.append("name", data.name.trim());
    data.url && formData.append("url", data.url);
    data.color && formData.append("hexCode", data.color);
    data.vendorID && formData.append("vendorID", data.vendorID);
    data.skuNumber && formData.append("skuNumber", data.skuNumber);
    formData.append("editable", data.editable);
    dispatch(set_loading(true));
    const response = await fetchingDataWithAxiosMiddleware(
      "PUT",
      `${apiService.walls()}`,
      formData
    );
    if (response?.status) {
      dispatch(get_filter_walls());
      dispatch(set_response_notification({ type: "success", text: "Success" }));
    }
  } catch (error) {
    dispatch(
      set_response_notification({
        type: "error",
        text: "Error -> " + error.message,
      })
    );
    dispatch(set_loading({ type: "walls", value: false }));
  }
};
export const delete_wall = (data) => async (dispatch) => {
  try {
    dispatch(set_loading({ type: "walls", value: true }));
    const response = await fetchingDataWithAxiosMiddleware(
      "DELETE",
      `${apiService.walls()}/${data}`
    );
    if (response?.status) {
      dispatch(get_filter_walls());
      dispatch(set_response_notification({ type: "success", text: "Success" }));
    }
  } catch (error) {
    dispatch(
      set_response_notification({
        type: "error",
        text: "Error -> " + error.message,
      })
    );
    dispatch(set_loading({ type: "walls", value: false }));
  }
};
export const walls_in_preprocess = (id) => async (dispatch) => {
  try {
    const response = await fetchingDataWithAxiosMiddleware(
      "GET",
      `${apiService.walls_in_preprocess()}/${id ? id : ""}`
    );
    if (response.status) {
      const data = await response.data;
      if (data) {
        if (data.length) {
          const preprocess = data.filter(
            (item) =>
              item.status === "in preprocess" ||
              item.status === "not preprocessed"
          ).length;
          if (preprocess) {
            dispatch(set_in_progress_walls(changeResponseDate(data, "walls")));
          } else {
            dispatch(get_filter_walls());
            dispatch(
              setInProgressMaterialLoading({ type: "walls", value: false })
            );
            dispatch(set_in_progress_walls(null));
          }
        }
      }
    }
  } catch (error) {
    dispatch(setInProgressMaterialLoading({ type: "walls", value: false }));
    dispatch(
      set_response_notification({
        type: "error",
        text: "Error -> " + error.message,
      })
    );
  }
};
export const preprocess_walls = (id) => async (dispatch) => {
  try {
    dispatch(setInProgressMaterialLoading({ type: "walls", value: id }));
    await fetchingDataWithAxiosMiddleware(
      "GET",
      `${apiService.preprocess_walls()}/${id ? id : ""}`
    );
    if (id) {
      dispatch(setInProgressMaterialLoading({ type: "walls", value: id }));
    } else {
      dispatch(setInProgressMaterialLoading({ type: "walls", value: false }));
    }
  } catch (error) {
    dispatch(setInProgressMaterialLoading({ type: "walls", value: false }));
    dispatch(
      set_response_notification({
        type: "error",
        text: "Error -> " + error.message,
      })
    );
  }
};

export const pause_wall = (data) => async (dispatch) => {
  try {
    dispatch(set_loading({ type: "walls", value: true }));
    const response = await fetchingDataWithAxiosMiddleware(
      "POST",
      `${apiService.walls()}/pause/${data}`
    );
    if (response?.status) {
      dispatch(set_response_notification({ type: "success", text: "Success" }));
      dispatch(get_filter_walls());
    }
  } catch (error) {
    dispatch(
      set_response_notification({
        type: "error",
        text: "Error -> " + error.message,
      })
    );
    dispatch(set_loading({ type: "walls", value: false }));
  }
};
