import React, { useEffect, useRef } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { get_forms } from "../../Redux/Actions/transferDataAction";
import { get_filter_floors } from "../../Redux/Actions/floor";
import { get_filter_walls } from "../../Redux/Actions/wall";
import {
  set_min_width_header,
  set_tablesPageInfo,
} from "../../Redux/Actions/commonAction";
import { checkVendorType } from "../../config";
import { get_vendors } from "../../Redux/Actions/vendor";

const Forms = () => {
  const state = useSelector((state) => state.common);
  const { vendors, walls, floors, forms, loading, tablesPageInfo, auth_info } =
    state;
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const tablePageOnChange = (e) => {
    dispatch(set_tablesPageInfo({ ...tablesPageInfo, forms: e }));
  };
  const formColumns = [
    {
      title: "ID",
      dataIndex: "key",
      sorter: (a, b) => b.key - a.key,
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Date & Time",
      dataIndex: "dataAndTime",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Zip Code",
      dataIndex: "zipCode",
    },
    {
      title: "Wall",
      dataIndex: "wallColorID",
      render: (record) => {
        if (record) {
          const chosenWall = walls?.find((wall) => wall.key === record);
          if (chosenWall) {
            return (
              <span>
                ID-{chosenWall.key}, {chosenWall.name}
              </span>
            );
          }
        } else {
          return <span style={{ color: "silver" }}>Not Chosen</span>;
        }
      },
    },
    {
      title: "Floor",
      dataIndex: "floorTileID",
      render: (record) => {
        if (record) {
          const chosenFloor = floors?.find((floor) => floor.key === record);
          if (chosenFloor) {
            return (
              <span>
                ID-{chosenFloor.key}, {chosenFloor.name}
              </span>
            );
          }
        } else {
          return <span style={{ color: "silver" }}>Not Chosen</span>;
        }
      },
    },
  ];

  useEffect(() => {
    if (!walls.length && checkVendorType(true, auth_info.type)) {
      dispatch(get_filter_walls());
    }
    if (!floors.length && checkVendorType(false, auth_info.type)) {
      dispatch(get_filter_floors());
    }
    if (!vendors.length) {
      dispatch(get_vendors());
    }
    dispatch(get_forms());
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      set_min_width_header(
        tableRef?.current?.children[0].children[0].children[0].children[0]
          .children[0].children[0]?.offsetWidth
      )
    );
  }, [tableRef, dispatch]);
  return (
    <div>
      <Table
        ref={tableRef}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        size={"small"}
        className={"globalTable"}
        loading={loading.forms}
        dataSource={forms}
        columns={formColumns}
        bordered
        onChange={tablePageOnChange}
        pagination={tablesPageInfo.forms}
      />
    </div>
  );
};

export default Forms;
