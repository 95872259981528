import React from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { Spin, Typography } from "antd";
import "./logsCharts.css";
import { chartStateSeries } from "../../../config";
import Ram from "../Ram";
import Disk from "../Disk";
const LogsChart = () => {
  const { Title } = Typography;
  const state = useSelector((state) => state.common);
  const { loading, requests, chart_filter } = state;
  const d = new Date();
  const oneMonthAgoMilliseconds = d.setMonth(d.getMonth() - chart_filter);
  let dateArray = [];
  if (requests.length) {
    requests.forEach((item) => {
      if (item.startedProcessing) {
        if (
          !dateArray.find((dataItem) => dataItem === item.dateFormatForChart) ||
          !dateArray.length
        ) {
          if (
            new Date(item.startedProcessing).getTime() > oneMonthAgoMilliseconds
          ) {
            dateArray.push(item.dateFormatForChart);
          }
        }
      }
    });
  }
  const logsChartState = {
    series: [
      {
        name: "Hardcoded sphere",
        data: chartStateSeries(
          requests,
          dateArray,
          "hardcodedsphere",
          "global"
        ),
      },
      {
        name: "Custom Sphere",
        data: chartStateSeries(requests, dateArray, "customsphere", "global"),
      },
      {
        name: "Still Photo",
        data: chartStateSeries(requests, dateArray, "stillphoto", "global"),
      },
      {
        name: "Errors",
        data: chartStateSeries(requests, dateArray, "ERROR", "global"),
      },
    ],
    options: {
      colors: ["#ff8000", "#0022fc", "#4DB671", "#fc0000"],
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [2, 2, 2, 2],
        curve: "straight",
        // dashArray: [0, 8, 5]
      },
      title: {
        text: "Count",
        align: "left",
      },
      legend: {
        tooltipHoverFormatter: function (val, opts) {
          return (
            val +
            " - " +
            opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
            ""
          );
        },
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: dateArray,
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
        ],
      },
      grid: {
        borderColor: "#f1f1f1",
      },
    },
  };
  const intervalChartState = {
    series: [
      {
        name: "Hardcoded sphere",
        data: chartStateSeries(
          requests,
          dateArray,
          "hardcodedsphere",
          "interval"
        ),
      },
      {
        name: "Custom Sphere",
        data: chartStateSeries(requests, dateArray, "customsphere", "interval"),
      },
      {
        name: "Still Photo",
        data: chartStateSeries(requests, dateArray, "stillphoto", "interval"),
      },
    ],
    options: {
      colors: ["#ff8000", "#0022fc", "#4DB671"],
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [2, 2, 2],
        curve: "straight",
        // dashArray: [0, 8, 5]
      },
      title: {
        text: "Second",
        align: "left",
      },
      legend: {
        tooltipHoverFormatter: function (val, opts) {
          return (
            val +
            " - " +
            opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
            ""
          );
        },
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: dateArray,
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val + " (Second) ";
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val + " (Second) ";
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val + " (Second) ";
              },
            },
          },
        ],
      },
      grid: {
        borderColor: "#f1f1f1",
      },
    },
  };
  const globalIntervalChartState = {
    series: [
      {
        name: "Hardcoded sphere",
        data: chartStateSeries(
          requests,
          dateArray,
          "hardcodedsphere",
          "globalInterval"
        ),
      },
      {
        name: "Custom Sphere",
        data: chartStateSeries(
          requests,
          dateArray,
          "customsphere",
          "globalInterval"
        ),
      },
      {
        name: "Still Photo",
        data: chartStateSeries(
          requests,
          dateArray,
          "stillphoto",
          "globalInterval"
        ),
      },
    ],
    options: {
      colors: ["#ff8000", "#0022fc", "#4DB671"],
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [2, 2, 2],
        curve: "straight",
        // dashArray: [0, 8, 5]
      },
      title: {
        text: "Second",
        align: "left",
      },
      legend: {
        tooltipHoverFormatter: function (val, opts) {
          return (
            val +
            " - " +
            opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
            ""
          );
        },
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: dateArray,
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val + " (Second) ";
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val + " (Second) ";
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val + " (Second) ";
              },
            },
          },
        ],
      },
      grid: {
        borderColor: "#f1f1f1",
      },
    },
  };
  const maxProcessTimeChartState = {
    series: [
      {
        name: "Hardcoded sphere",
        data: chartStateSeries(
          requests,
          dateArray,
          "hardcodedsphere",
          "maximum"
        ),
      },
      {
        name: "Custom Sphere",
        data: chartStateSeries(requests, dateArray, "customsphere", "maximum"),
      },
      {
        name: "Still Photo",
        data: chartStateSeries(requests, dateArray, "stillphoto", "maximum"),
      },
    ],
    options: {
      colors: ["#ff8000", "#0022fc", "#4DB671"],
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [2, 2, 2],
        curve: "straight",
        // dashArray: [0, 8, 5]
      },
      title: {
        text: "Second",
        align: "left",
      },
      legend: {
        tooltipHoverFormatter: function (val, opts) {
          return (
            val +
            " - " +
            opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
            ""
          );
        },
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: dateArray,
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val + " (Second) ";
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val + " (Second) ";
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val + " (Second) ";
              },
            },
          },
        ],
      },
      grid: {
        borderColor: "#f1f1f1",
      },
    },
  };

  return (
    <div className="logs">
      {loading.requests ? (
        <div className={"chartLoading"}>
          <Spin size={"large"} />
        </div>
      ) : (
        <>
          <Chart
            options={logsChartState.options}
            series={logsChartState.series}
            type="line"
            width={1000}
            height={500}
          />
          <Title level={4}>First Photo Processing Time</Title>
          <Chart
            options={intervalChartState.options}
            series={intervalChartState.series}
            type="line"
            width={1000}
            height={500}
          />
          <Title level={4}>Average processing Time</Title>
          <Chart
            options={globalIntervalChartState.options}
            series={globalIntervalChartState.series}
            type="line"
            width={1000}
            height={500}
          />
          <Title level={4}>Maximum Processing Time</Title>
          <Chart
            options={maxProcessTimeChartState.options}
            series={maxProcessTimeChartState.series}
            type="line"
            width={1000}
            height={500}
          />
        </>
      )}
      <div
        style={{
          width: "100%",
          padding: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Ram />
        <Disk />
      </div>
    </div>
  );
};

export default LogsChart;
