import {
  fetchingDataWithAxiosMiddleware,
  set_loading,
  set_response_notification,
  setServerIsDown,
} from "./commonAction";
import apiService from "../../serviceUtils";
import { changeResponseDate } from "../../config";

export const SET_VENDORS = "SET_VENDORS";
export const SET_CHECKED_VENDOR_PAUSED = "SET_CHECKED_VENDOR_PAUSED";

export const set_checked_vendor_paused = () => {
  return {
    type: SET_CHECKED_VENDOR_PAUSED,
  };
};

export const set_vendors = (payload) => {
  return {
    type: SET_VENDORS,
    payload,
  };
};

export const get_vendors = () => async (dispatch) => {
  try {
    dispatch(set_loading({ type: "vendors", value: true }));
    const response = await fetchingDataWithAxiosMiddleware(
      "GET",
      `${apiService.get_vendors()}`
    );
    if (response.status) {
      const data = await response.data;
      if (data) {
        dispatch(set_vendors(changeResponseDate(data, "vendors")));
      } else if (response.status === 204) {
        dispatch(set_vendors(changeResponseDate([], "vendors")));
      }
      dispatch(set_loading({ type: "vendors", value: false }));
    }
  } catch (error) {
    if (error.message === "Network Error") {
      dispatch(setServerIsDown(true));
    }
    dispatch(set_loading({ type: "vendors", value: false }));
    dispatch(
      set_response_notification({
        type: "error",
        text: "Error -> " + error.message,
      })
    );
  }
};
export const add_vendor = (data) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append("name", data.name.trim());
    formData.append("url", data.url);
    formData.append("type", JSON.stringify(data.type));
    formData.append("logo", data.logo);
    formData.append("impressionLimit", data.impressionLimit);
    formData.append("monthlyBudget", data.monthlyBudget);
    formData.append("costPerView", JSON.stringify(data.costPerView));
    dispatch(set_loading({ type: "vendors", value: true }));
    const response = await fetchingDataWithAxiosMiddleware(
      "POST",
      `${apiService.get_vendors()}`,
      formData
    );
    if (response?.status) {
      await response.data;
      // dispatch(set_vendors(changeResponseDate(data, "vendors")));
      // dispatch(set_loading(false));
      dispatch(get_vendors());
      dispatch(set_response_notification({ type: "success", text: "Success" }));
    }
  } catch (error) {
    dispatch(
      set_response_notification({
        type: "error",
        text: "Error -> " + error.message,
      })
    );
    dispatch(set_loading({ type: "vendors", value: false }));
  }
};
export const edit_vendor = (data) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append("id", data.key);
    data.name && formData.append("name", data.name.trim());
    data.url && formData.append("url", data.url);
    data.type.length && formData.append("type", JSON.stringify(data.type));
    data.logo && formData.append("logo", data.logo);
    data.impressionLimit &&
      formData.append("impressionLimit", data.impressionLimit);
    data.monthlyBudget && formData.append("monthlyBudget", data.monthlyBudget);
    data.costPerView &&
      formData.append("costPerView", JSON.stringify(data.costPerView));
    dispatch(set_loading({ type: "vendors", value: true }));
    const response = await fetchingDataWithAxiosMiddleware(
      "PUT",
      `${apiService.get_vendors()}`,
      formData
    );
    if (response?.status) {
      await response.data;
      // dispatch(set_vendors(changeResponseDate(data, "vendors")));
      dispatch(get_vendors());
      dispatch(set_response_notification({ type: "success", text: "Success" }));
      // dispatch(set_loading(false));
    }
  } catch (error) {
    dispatch(
      set_response_notification({
        type: "error",
        text: "Error -> " + error.message,
      })
    );
    dispatch(set_loading({ type: "vendors", value: false }));
  }
};
export const delete_vendor = (data) => async (dispatch) => {
  try {
    dispatch(set_loading(true));
    const response = await fetchingDataWithAxiosMiddleware(
      "DELETE",
      `${apiService.get_vendors()}/${data}`
    );
    if (response?.status) {
      await response.data;
      dispatch(get_vendors());
      dispatch(set_response_notification({ type: "success", text: "Success" }));
      dispatch(set_loading({ type: "vendors", value: true }));
    }
  } catch (error) {
    dispatch(
      set_response_notification({
        type: "error",
        text: "Error -> " + error.message,
      })
    );
    dispatch(set_loading({ type: "vendors", value: false }));
  }
};
export const pause_vendor = (data) => async (dispatch) => {
  try {
    dispatch(set_loading({ type: "vendors", value: true }));
    const response = await fetchingDataWithAxiosMiddleware(
      "POST",
      `${apiService.get_vendors()}/pause/${data}`
    );
    if (response?.status) {
      dispatch(set_checked_vendor_paused());
      dispatch(set_response_notification({ type: "success", text: "Success" }));
      dispatch(get_vendors());
    }
  } catch (error) {
    dispatch(
      set_response_notification({
        type: "error",
        text: "Error -> " + error.message,
      })
    );
    dispatch(set_loading({ type: "vendors", value: false }));
  }
};
