import {
  SET_ACTIVE_TAB_IN_LOGS,
  SET_CHART_FILTER,
  SET_COUNTRIES,
  SET_FILTER_PARAMS,
  SET_FORMS,
  SET_IS_MODAL,
  SET_LOADING,
  SET_MATERIAL_ID_FOR_PROCESSING,
  SET_MIN_WIDTH_HEADER,
  SET_REQUESTS,
  SET_RESPONSE_NOTIFICATION,
  SET_SPACE_INFO,
  SET_TABLES_PAGE_INFO,
  SET_VIEW_ALL_PROCESS,
  SET_IN_PROGRESS_MATERIALS,
  SET_BACKEND_ERRORS,
  SET_AFFILIATE_LINKS,
  SET_AFFILIATE_INCOMES,
  SET_DEBUGGING_INFO,
  SET_SERVER_IS_DOWN,
  SET_TESTING_MODE,
} from "../Actions/commonAction";
import { SET_IN_PROGRESS_WALLS, SET_WALLS } from "../Actions/wall";
import { SET_FLOORS, SET_IN_PROGRESS_FLOORS } from "../Actions/floor";
import { SET_CHECKED_VENDOR_PAUSED, SET_VENDORS } from "../Actions/vendor";
import { commonFilter, defaultPaginationInfo } from "../../config";
import { LOG_OUT, SET_AUTH_INFO } from "../Actions/auth";

const initialState = {
  auth_info: localStorage.getItem("auth_info")
    ? JSON.parse(localStorage.getItem("auth_info"))
    : "",
  loading: {
    vendors: false,
    walls: false,
    floors: false,
    rugs: false,
    wallDecors: false,
    floorDecors: false,
    tableDecors: false,
    forms: false,
    affiliateLinks: false,
    affiliateIncomes: false,
    requests: false,
    stillPhotos: false,
    backendErrors: false,
    debuggingInfo: false,
    testingMode: false,
  },
  responseNotification: null,
  only_one_wall_loading: false,
  only_one_floor_loading: false,
  is_modal: null,
  filterParams: {
    vendors: commonFilter("vendors", false),
    walls: commonFilter("walls", false),
    floors: commonFilter("floors", false),
    forms: commonFilter("forms", false),
    rugs: commonFilter("rugs", false),
    wallDecors: commonFilter("wallDecors", false),
    floorDecors: commonFilter("floorDecors", false),
    tableDecors: commonFilter("tableDecors", false),
  },
  inProgressMaterialLoading: {
    walls: false,
    floors: false,
    rugs: false,
    decors: false,
  },
  checked_vendor_paused: null,
  vendors: [],
  walls: [],
  floors: [],
  forms: [],
  backendErrors: [],
  debuggingInfo: [],
  requests: [],
  affiliateLinks: [],
  affiliateIncomes: [],
  countries: [],
  space_info: null,
  view_impressions_and_clicks_info: false,
  material_id_for_processing: false,
  active_tab_in_logs: "debuggingInfo",
  chart_filter: 1,
  tablesPageInfo: sessionStorage.getItem("tablesPageInfo")
    ? JSON.parse(sessionStorage.getItem("tablesPageInfo"))
    : {
        walls: defaultPaginationInfo,
        floors: defaultPaginationInfo,
        vendors: defaultPaginationInfo,
        forms: defaultPaginationInfo,
        logs: defaultPaginationInfo,
        rugs: defaultPaginationInfo,
        wallDecors: defaultPaginationInfo,
        floorDecors: defaultPaginationInfo,
        tableDecors: defaultPaginationInfo,
      },
  min_width_header: 0,
  serverIsDown: false,
  testingMode: false,
};

export const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH_INFO:
      return {
        ...state,
        auth_info: localStorage.getItem("auth_info")
          ? JSON.parse(localStorage.getItem("auth_info"))
          : "",
      };
    case LOG_OUT:
      return {
        ...state,
        auth_info: "",
        walls: [],
        floors: [],
        forms: [],
        vendors: [],
        filterParams: {
          walls: commonFilter("walls", true),
          floors: commonFilter("floors", true),
          forms: commonFilter("forms", true),
          rugs: commonFilter("rugs", true),
          wallDecors: commonFilter("wallDecors", true),
          floorDecors: commonFilter("floorDecors", true),
          tableDecors: commonFilter("tableDecors", true),
        },
      };

    case SET_VENDORS:
      return { ...state, vendors: action.payload };
    case SET_WALLS:
      return { ...state, walls: action.payload };
    case SET_FLOORS:
      return { ...state, floors: action.payload };
    case SET_FORMS:
      return { ...state, forms: action.payload };
    case SET_AFFILIATE_LINKS:
      return { ...state, affiliateLinks: action.payload };
    case SET_AFFILIATE_INCOMES:
      return { ...state, affiliateIncomes: action.payload };
    case SET_REQUESTS:
      return { ...state, requests: action.payload };
    case SET_BACKEND_ERRORS:
      return { ...state, backendErrors: action.payload };
    case SET_DEBUGGING_INFO:
      return { ...state, debuggingInfo: action.payload };
    case SET_LOADING:
      state.loading[action.payload.type] = action.payload.value;
      return { ...state };
    case SET_RESPONSE_NOTIFICATION:
      return { ...state, responseNotification: action.payload };
    case SET_IN_PROGRESS_MATERIALS:
      state.inProgressMaterialLoading[action.payload.type] =
        action.payload.value;
      return {
        ...state,
        inProgressMaterialLoading: { ...state.inProgressMaterialLoading },
      };
    case SET_IS_MODAL:
      return { ...state, is_modal: action.payload };
    case SET_FILTER_PARAMS:
      return { ...state, filterParams: action.payload };
    case SET_IN_PROGRESS_WALLS:
      return { ...state, in_progress_walls: action.payload };
    case SET_IN_PROGRESS_FLOORS:
      return { ...state, in_progress_floors: action.payload };
    case SET_CHECKED_VENDOR_PAUSED:
      return { ...state, checked_vendor_paused: !state.checked_vendor_paused };
    case SET_SPACE_INFO:
      return { ...state, space_info: action.payload };
    case SET_COUNTRIES:
      return { ...state, countries: action.payload };
    case SET_VIEW_ALL_PROCESS:
      return { ...state, view_all_process: action.payload };
    case SET_MATERIAL_ID_FOR_PROCESSING:
      return { ...state, material_id_for_processing: action.payload };
    case SET_ACTIVE_TAB_IN_LOGS:
      return { ...state, active_tab_in_logs: action.payload };
    case SET_CHART_FILTER:
      return { ...state, chart_filter: action.payload };
    case SET_TABLES_PAGE_INFO:
      return { ...state, tablesPageInfo: action.payload };
    case SET_MIN_WIDTH_HEADER:
      return { ...state, min_width_header: action.payload };
    case SET_SERVER_IS_DOWN:
      return { ...state, serverIsDown: action.payload };
    case SET_TESTING_MODE:
      return { ...state, testingMode: action.payload };
    default:
      return state;
  }
};
