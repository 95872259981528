import Config from "./config";
import { queryParamsForCommonFilter } from "../config";

class Rugs extends Config {
  changeURL(id) {
    if (id) {
      return `${this.server_domain_endpoint}/rugs/${id}`;
    }
    return `${this.server_domain_endpoint}/rugs`;
  }

  getAllURL(params) {
    return `${
      this.server_domain_endpoint
    }/rugs/filter?${queryParamsForCommonFilter(params)}`;
  }
  rugPreproccess(id) {
    return `${this.server_domain_endpoint}/rugs/preprocess/${id}`;
  }
  handlerData(data) {
    let rugsDataEx = {};
    if (Array.isArray(data)) {
      const newData = [];
      data.forEach((item) => {
        rugsDataEx = {
          ...item,
          key: item.id,
          material: item.productImageURL,
          url: item.urlOnVendorPage,
          //todo formIds is not
          formIds: [],
        };
        newData.push(rugsDataEx);
      });
      return newData;
    }

    return rugsDataEx;
  }
}

export default Rugs;
