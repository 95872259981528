import React from "react";
import { Modal, Table, Tooltip } from "antd";
import "./modal.css";
import { useSelector } from "react-redux";

const ViewImpressionsAndClicksInfo = ({ open, setOpen }) => {
  const state = useSelector((state) => state.common);
  const { auth_info } = state;
  const handleOk = () => {
    setOpen();
  };
  const handleCancel = () => {
    setOpen();
  };
  const dataSource = [];
  open[open.type].forEach((item, index) => {
    if (item) {
      item.key = item.id ? item.id : `${index + 1}`;
      dataSource.push(item);
    }
  });
  let columns = [
    {
      title: "ID",
      dataIndex: "key",
    },
    {
      title: "Interaction Source",
      dataIndex: "interactionSource",
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      render: (record) => {
        return <Tooltip title="UTC time Zone">{record}</Tooltip>;
      },
    },
    {
      title: "ip Address",
      dataIndex: "ipAddress",
    },
    {
      title: "Location",
      dataIndex: "city",
    },
  ];
  const showColumns = (columns, isImpression) => {
    if (auth_info.isVendor) {
      columns = columns.filter(
        (item) =>
          item.title !== "ID" &&
          item.title !== "ip Address" &&
          // item.title !== "Location" &&
          item.title !== "Interaction Source"
      );
    } else {
      if (!isImpression) {
        columns = columns.filter((item) => item.title !== "Interaction Source");
      }
    }

    return [...columns];
  };
  columns = showColumns(columns, open.type === "impressions");
  return (
    <div>
      <Modal
        title={`View ${open.type} for ${open.name}`}
        open={open}
        width={900}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={""}
      >
        <Table
          dataSource={dataSource}
          columns={columns}
          bordered
          size={"small"}
        />
      </Modal>
    </div>
  );
};

export default ViewImpressionsAndClicksInfo;
