import React, { useEffect, useRef } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAffiliateIncomes } from "../../Redux/Actions/transferDataAction";
import {
  set_min_width_header,
  set_tablesPageInfo,
} from "../../Redux/Actions/commonAction";
import { showDetails } from "../../config";

const AffiliateIncomes = () => {
  const state = useSelector((state) => state.common);
  const { loading, tablesPageInfo, affiliateIncomes } = state;
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const tablePageOnChange = (e) => {
    dispatch(set_tablesPageInfo({ ...tablesPageInfo, forms: e }));
  };
  const affiliateIncomesColumn = [
    {
      title: "Affiliate Id",
      dataIndex: "key",
      sorter: (a, b) => b.key - a.key,
    },
    {
      title: "Paid Income",
      dataIndex: "paidIncome",
    },
    {
      title: "Records",
      dataIndex: "",
      render: (record) => {
        return showDetails(record, "records", dispatch);
      },
    },
    {
      title: "Pending Income",
      dataIndex: "pendingIncome",
    },
    {
      title: "Total Income",
      dataIndex: "totalIncome",
    },
  ];

  useEffect(() => {
    dispatch(getAffiliateIncomes());
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      set_min_width_header(
        tableRef?.current?.children[0].children[0].children[0].children[0]
          .children[0].children[0]?.offsetWidth
      )
    );
  }, [tableRef, dispatch]);
  return (
    <div>
      <Table
        ref={tableRef}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        size={"small"}
        className={"globalTable"}
        loading={loading.affiliateIncomes}
        dataSource={affiliateIncomes}
        columns={affiliateIncomesColumn}
        bordered
        onChange={tablePageOnChange}
        pagination={tablesPageInfo.forms}
      />
    </div>
  );
};

export default AffiliateIncomes;
