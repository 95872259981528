import React from "react";
import { Modal } from "antd";
import { useDispatch } from "react-redux";
import { delete_credit_card } from "../../Redux/Actions/creditCard";
import { delete_vendor } from "../../Redux/Actions/vendor";
import { delete_floor } from "../../Redux/Actions/floor";
import { delete_wall } from "../../Redux/Actions/wall";
import { useLocation } from "react-router-dom";
import { activeTab } from "../../config";
import { deleteRug } from "../../Redux/Actions/rugs";
import { deleteDecor } from "../../Redux/Actions/decor";
import { deleteAffiliateLinks } from "../../Redux/Actions/transferDataAction";

const DeleteModal = ({ open, setOpen }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const handleOk = () => {
    if (typeof open === "number") {
      dispatch(delete_credit_card(open));
    } else if (location.pathname === "/floors") {
      dispatch(delete_floor(open.key));
    } else if (location.pathname === "/walls") {
      dispatch(delete_wall(open.key));
    } else if (location.pathname === "/vendors") {
      dispatch(delete_vendor(open.key));
    } else if (location.pathname === "/rugs") {
      dispatch(deleteRug(open.key));
    } else if (location.pathname === "/wallDecors") {
      dispatch(deleteDecor(open.key, "wall"));
    } else if (location.pathname === "/floorDecors") {
      dispatch(deleteDecor(open.key, "floor"));
    } else if (location.pathname === "/tableDecors") {
      dispatch(deleteDecor(open.key, "table"));
    } else if (location.pathname === "/affiliateLinks") {
      dispatch(deleteAffiliateLinks(open.key));
    }
    setOpen();
  };
  const handleCancel = () => {
    setOpen();
  };
  return (
    <div>
      <Modal
        title={`Delete Modal for ${activeTab(location.pathname)}`}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        Do you want to Delete the
        {typeof open === "number" ? " vendor card" : " this row"} ?
      </Modal>
    </div>
  );
};

export default DeleteModal;
