import React, {useEffect} from "react";
import {Modal, Table} from "antd";
import "./modal.css";
import {useDispatch, useSelector} from "react-redux";
import {get_forms} from "../../Redux/Actions/transferDataAction";
import {get_filter_floors} from "../../Redux/Actions/floor";
import {get_filter_walls} from "../../Redux/Actions/wall";
import {initialParamsForFilter} from "../../config";

const ViewFormsModal = ({open, setOpen}) => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.common);
    const {forms, walls, floors, loading} = state;

    useEffect(() => {
        dispatch(get_forms({...initialParamsForFilter, country: ""}));
        if (!walls.length) {
            dispatch(get_filter_walls());
        }
        if (!floors.length) {
            dispatch(get_filter_floors());
        }
    }, [dispatch]);
    const handleOk = () => {
        setOpen();
    };
    const handleCancel = () => {
        setOpen();
    };
    const filteredArray = forms.filter((value) =>
        open.formIds.includes(value.key)
    );

    const formColumns = [
        {
            title: "ID",
            dataIndex: "key",
            sorter: (a, b) => b.key - a.key,
        },
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Date & Time",
            dataIndex: "dataAndTime",
        },
        {
            title: "Email",
            dataIndex: "email",
        },
        {
            title: "Phone",
            dataIndex: "phone",
        },
        {
            title: "Zip Code",
            dataIndex: "zipCode",
        },
        {
            title: "Wall",
            dataIndex: "wallColorID",
            render: (record) => {
                if (record) {
                    const chosenWall = walls?.find((wall) => wall.key === record);
                    if (chosenWall) {
                        return (
                            <span>
                ID-{chosenWall.key}, {chosenWall.name}
              </span>
                        );
                    }
                } else {
                    return <span style={{color: "silver"}}>Not Chosen</span>;
                }
            },
        },
        {
            title: "Floor",
            dataIndex: "floorTileID",
            render: (record) => {
                if (record) {
                    const chosenFloor = floors?.find((floor) => floor.key === record);
                    if (chosenFloor) {
                        return (
                            <span>
                ID-{chosenFloor.key}, {chosenFloor.name}
              </span>
                        );
                    }
                } else {
                    return <span style={{color: "silver"}}>Not Chosen</span>;
                }
            },
        },
    ];
    return (
        <div>
            <Modal
                title={`View Forms for ${open.name}`}
                open={open}
                width={900}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={""}
            >
                <Table
                    dataSource={filteredArray}
                    columns={formColumns}
                    loading={loading}
                    bordered
                    size={"small"}
                />
            </Modal>
        </div>
    );
};

export default ViewFormsModal;
